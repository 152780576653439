
.article-content {
    display: flex;
    flex-direction: column;
    height: calc(100% - 50px);
    padding: 0 0 50px;

    .article-wrap {
        height: 100%;
        display: flex;
        flex-direction: column;

        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;

            .el-scrollbar__view {
                display: flex;
                flex-direction: column;
                height: 100%;
                padding: 0 20px;
            }
        }
    }

    .top {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #F1F5FF;
        height: 40px;
        padding-bottom: 10px;

        .genera-breadcrumb {
            display: flex;
            align-items: center;

            &:before {
                content: unset;
            }
        }
    }

    .main-content {
        margin-top: 20px;
        flex: 1;
        height: 1%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .s500-input {
            width: 500px;
        }

        .s800-input {
            width: 800px;
        }
    }

    .page-button {
        text-align: center;
    }

    .cover-uploader {
        width: 800px;

        ::v-deep.el-upload {
            display: flex;
            align-items: center;

            .inner-box {
                width: 375px;
                height: 160px;
                background: #E7F6EF;
                border: 1px dashed #2DC079;
                border-radius: 4px;

                .inner-content {
                    padding: 29px 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    i {
                        font-size: 30px;
                        color: #2DC079;
                    }

                    .inner-text {
                        font-size: 16px;
                        color: #666666;
                        margin-top: 20px;
                    }
                }
            }

            .cover {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 375px;
                height: 160px;
                margin-left: 15px;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                }

                .cover-bottom {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 32px;
                    background: #000000;
                    opacity: 0.5;
                    border-radius: 2px;
                    color: #ffffff;
                    font-size: 16px;
                    line-height: 36px;
                }
            }
        }
    }

    .item-txt-tip {
        color: #999999;
    }
}
